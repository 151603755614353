import 'firebase/auth';
import * as ciap from 'gcip-iap';

import getConfigs from './authentication/configs.js';
import AuthenticationHandler from './authentication/authenticationHandler';

import {
  getElementById,
  getElementValueById,
  onDocumentReady,
} from './lib/dom';

import { showFormError, hideFormError } from './validation/form';

import {
  validateEmail,
  showEmailError,
  hideEmailError,
} from './validation/email';

import ta from './lib/ta';
import createDebugConsole from './lib/debugConsole';

import pageRefresher from './lib/pageRefresher';

const tenantStart = () => {
  ta.initialize();

  const debugConsole = createDebugConsole('tenant');
  debugConsole.log('tenantStart()');

  const formElem = <HTMLFormElement>getElementById('tenantForm');
  const formErrorElem = <HTMLFormElement>getElementById('formError');
  const emailElem = <HTMLInputElement>getElementById('email');
  const emailErrorElem = <HTMLElement>getElementById('emailError');
  const countdownElem = <HTMLElement>getElementById('countdown');

  if (
    !formElem ||
    !formErrorElem ||
    !emailElem ||
    !emailErrorElem ||
    !countdownElem
  ) {
    console.error('Error initializing tenant page.');
    return;
  }

  const apiKey = getElementValueById('apiKey');
  const authDomain = getElementValueById('authDomain');

  const configs = getConfigs(apiKey, authDomain);

  debugConsole.log('creating AuthenticationHandler');
  const authenticationHandler = new AuthenticationHandler(configs);

  debugConsole.log('creating ciap.Authentication');
  const ciapInstance = new ciap.Authentication(authenticationHandler);

  try {
    debugConsole.log('calling ciapInstance.start()');
    ciapInstance.start();
  } catch (error: any) {
    console.error(`Error starting ciap: ${error?.message}`);
    return;
  }

  const handleEmailChange = () => {
    hideFormError(formErrorElem);
    hideEmailError(emailElem, emailErrorElem);
  };

  const handleFormSubmit = (event: Event) => {
    event.preventDefault();
    hideFormError(formErrorElem);

    const { valid, message } = validateEmail(emailElem.value);
    if (valid) {
      authenticationHandler.onEmailEntered(emailElem.value);
    } else {
      showEmailError(emailElem, emailErrorElem, message);
    }
  };

  showFormError(formErrorElem);

  formElem.addEventListener('submit', handleFormSubmit);
  emailElem.addEventListener('input', handleEmailChange);

  pageRefresher.start(9.75 * 60, 0, countdownElem);
};

onDocumentReady(tenantStart);
