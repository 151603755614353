import createDebugConsole from './debugConsole';

let debugConsole: Console;

let refreshTimeout: number | null = null;
let countdownTimeout: number | null = null;
let countdownInterval: number | null = null;

function clearCountdownInterval() {
  if (countdownInterval !== null) {
    clearInterval(countdownInterval);
    countdownInterval = null;
  }
}

function startCountdown(countdownSeconds: number, countdownElem: HTMLElement) {
  let timeLeft = countdownSeconds;

  countdownInterval = window.setInterval(() => {
    const message = `Refreshing page in ${timeLeft} seconds...`;

    countdownElem.textContent = message;
    debugConsole.log(message);

    timeLeft--;

    if (timeLeft < 0) {
      clearCountdownInterval();
    }
  }, 1000);
}

function startCountdownTimer(
  countdownSeconds: number,
  countdownElem: HTMLElement,
  refreshSeconds: number,
) {
  debugConsole.log(`Countdown final ${countdownSeconds} seconds`);

  const countdownDelay = refreshSeconds * 1000 - countdownSeconds * 1000;

  countdownTimeout = window.setTimeout(() => {
    startCountdown(countdownSeconds, countdownElem);
  }, countdownDelay);
}

function startRefreshTimer(refreshSeconds: number) {
  debugConsole.log(`Refresh page in ${refreshSeconds / 60} minutes`);

  const refreshDelay = refreshSeconds * 1000;

  refreshTimeout = window.setTimeout(() => {
    window.location.replace(`/`);
  }, refreshDelay);
}

const start = (
  refreshSeconds: number,
  countdownSeconds?: number,
  countdownElem?: HTMLElement,
): void => {
  debugConsole = createDebugConsole('pageRefresher');

  startRefreshTimer(refreshSeconds);

  if (countdownSeconds && countdownElem) {
    startCountdownTimer(countdownSeconds, countdownElem, refreshSeconds);
  }
};

// Clear intervals and timeouts before leaving the page
window.addEventListener('beforeunload', () => {
  if (refreshTimeout !== null) {
    clearTimeout(refreshTimeout);
    refreshTimeout = null;
  }

  if (countdownTimeout !== null) {
    clearTimeout(countdownTimeout);
    countdownTimeout = null;
  }

  clearCountdownInterval();
});

export default { start };
