const isDebugMode = () => {
  return window?.TA?.config?.debug || false;
};

const createDebugConsole = (prefix: string, enabled?: boolean): Console => {
  if (enabled === undefined) {
    enabled = isDebugMode();
  }
  const origConsole = window.console as any;
  const debugConsole = { ...origConsole };
  for (let key in debugConsole) {
    if (
      debugConsole.hasOwnProperty(key) &&
      typeof debugConsole[key] === 'function'
    ) {
      const func = debugConsole[key];
      debugConsole[key] = function () {
        if (enabled) {
          func.apply(debugConsole, [`${prefix}:`, ...arguments]);
        }
      };
    }
  }
  return debugConsole;
};

export default createDebugConsole;
