import { getElementValueById } from './dom';
import createDebugConsole from './debugConsole';

const initialize = (): void => {
  const configString = getElementValueById('config');
  let config = {};
  try {
    config = JSON.parse(configString);
  } catch (error: any) {
    console.error(`error parsing config string: ${error?.message}`);
  }

  window.TA = window.TA || {};
  window.TA.config = { debug: false, ...config };

  const debugConsole = createDebugConsole('ta');
  debugConsole.log(`debug: ${window.TA.config?.debug}`);
};

export default { initialize };
