import { isStandardEmail, validateEmail } from '../../../validation/email';

const showEmailError = (
  inputElem: HTMLInputElement,
  errorElem: HTMLElement,
  message: string,
): void => {
  if (inputElem) {
    inputElem.classList.add('input-invalid');
  }

  if (errorElem) {
    errorElem.innerText = message;
    errorElem.classList.remove('hide');
  }
};

const hideEmailError = (
  inputElem: HTMLInputElement,
  errorElem: HTMLElement,
): void => {
  if (inputElem) {
    inputElem.classList.remove('input-invalid');
  }

  if (errorElem) {
    errorElem.innerText = '';
    errorElem.classList.add('hide');
  }
};

const validStandardEmail = (
  email: string,
): { valid: boolean; message: string } => {
  const { valid, message } = validateEmail(email);
  if (!valid) {
    return { valid, message };
  }
  if (!isStandardEmail(email)) {
    return {
      valid: false,
      message: `Your password is administered through your company's Single Sign On (SSO) Identity Provider. Contact your company's Help Desk for assistance.`,
    };
  }
  return {
    valid: true,
    message: '',
  };
};

export { validateEmail, validStandardEmail, showEmailError, hideEmailError };
